<template>
  <v-row justify="center">
    <v-dialog v-model="shower" scrollable persistent max-width="500px" >
        <v-card>
            <v-card-title>{{$t('admin.settingfeature')}} {{nametitle}}</v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-overlay :value="loadprogress" absolute :color="color.BG" dark>
              <v-progress-circular indeterminate size="64" :color="color.theme"></v-progress-circular>
              <!-- <span :style="colorProgress">&nbsp; loading</span> -->
              </v-overlay>
                <!-- <v-flex offset-lg8 offset-xs8>
                <v-switch
                    :color="color.theme"
                    :value="color.theme"
                    inset
                    class="text-right"
                    v-model="selectall"
                    :label="switchall"
                ></v-switch>
                </v-flex> -->
                <!-- <v-sheet class="pa-5" v-if="$t('default') === 'th'">
                <v-switch v-for="(items,i) in feature" :key="i"
                    inset
                    :color="color.theme"
                    :value="items.feature_name_en"
                    :label="items.feature_name_th"
                    v-model="selected"
                ></v-switch>
                </v-sheet>
                 <v-sheet class="pa-5" v-else>
                <v-switch v-for="(items,i) in feature" :key="i"
                    inset
                    :color="color.theme"
                    :value="items.feature_name_en"
                    :label="items.feature_name_en"
                    v-model="selected"
                ></v-switch>
                </v-sheet> -->
                <v-list v-if="$t('default') === 'en'">
                  <v-list-item v-for="(items,i) in feature" :key="i"><v-list-item-action><v-switch 
                    inset
                    :color="color.theme"
                    :value="items.feature_name_en"
                    v-model="selected"
                ></v-switch></v-list-item-action>
                <v-list-item-content class="text-center">
                          <v-list-item-title v-text="items.feature_name_en"></v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action><v-icon>{{items.feature_icon[0]}}</v-icon></v-list-item-action>
                </v-list-item>
                </v-list>
                <v-list v-else>
                  <v-list-item v-for="(items,i) in feature" :key="i"><v-list-item-action><v-switch 
                    inset
                    :color="color.theme"
                    :value="items.feature_name_en"
                    v-model="selected"
                ></v-switch></v-list-item-action>
                <v-list-item-content class="text-center">
                          <v-list-item-title v-text="items.feature_name_th"></v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action><v-icon>{{items.feature_icon[0]}}</v-icon></v-list-item-action>
                </v-list-item>
                </v-list>
              <v-card-text>
      
    </v-card-text>
            </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" outlined @click="closemanagefeature()">{{$t('admin.close')}}</v-btn>
           <v-btn  :color="color.theme" :dark="color.darkTheme" @click="settingfeature()">{{$t('admin.submit')}}</v-btn>        
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>  
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import { validationMixin } from 'vuelidate'
import { required, maxLength, email } from 'vuelidate/lib/validators'
import gbfGenerate from "@/globalFunctions/generateAuthorize";
const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000
});

export default {
     mixins: [validationMixin],
   validations: {
      filename: { required },
    },
    props: ['show','datafeature','functionsetting'],
    data: function() {
      return {
        SWmydrive:false,SWmyinbox:false,SWmyoutbox:false,SWshare:false,SWtrash:false,SWstared:false,
        createprogress: false,
        nametitle:"",
        selectall:false,
        switchfeature: '',
        feature:[],
        selected:[],
        test:1,
        loadprogress:true,
      }

    },
    computed: {
      ...mapState(['username', 'authorize','account_active','color','role_level']),
      ...mapState({processloader: 'loading'}),
      ...mapGetters(['dataUsername', 'dataAuthorize','dataAccountActive', 'dataAccesstoken', 'dataBusinessProfile','dataCitizenProfile', 'dataDepartmentAccessId','dataAccountId']),
        shower :{
            get() {
                if(this.show === true){
                  if(this.test === 1){
                    console.log("wwweewwee",this.datafeature);
                    console.log(this.dataBusinessProfile);
                    console.log(this.role_level);
                    
                    this.getfeature();
                  }
                  this.test = 0;
                }
                return this.show
            },
            set (value) {
                if (!value) {
                 this.selected=[];
                }
            }
        },
        colorProgress(){
      return 'color:'+this.color.theme+';'
    },
        
    },
    
    methods:{
      async getfeature(){
        this.selected=[];
        console.log("f",this.datafeature);
        var payload = {
        account_id : this.dataAccountId,        
        };
        let auth = await gbfGenerate.generateToken();
        this.axios
        .post(
          process.env.VUE_APP_SERVICE_ADMIN + "/api/get_feature",
          payload,{headers: { Authorization: 'MjAyMS0wOC0wMlQxNTowNl9CZWFyZXIgYmNkNGZkZjE1MzllMTcwOGEyMzE5ZDk1ZmViNTE1MDE2NTA0YTY5MjliYjExM2QxMWZmZTg1ZjljZWQyMjJkZGQyYmNjZDk1NjBjMGZhMDU5NDc4N2E5Yzc3YzI2YWEyZTVmYTZiMGFhYjJjMmYzNjAzMTc3MWJmODk4NDU5OWU=' },}
        ).then(response => {
          
            if (response.data.status === "OK") {
              this.feature=[];
              for(let i = 0;i<response.data.data.length;i++){
                if(response.data.data[i]["type"] === "normal"){
                  // ปิดไ้้วก่อน เผื่อเปิดในอนาคต
                  if( !['inbox','outbox'].includes(response.data.data[i]["feature_name_en"]) ) {
                    let databuffer ={};
                    databuffer["feature_name_en"] = response.data.data[i]["feature_name_en"];
                    databuffer["feature_name_th"] = response.data.data[i]["feature_name_th"];
                    databuffer["feature_id"] = response.data.data[i]["id"];
                    databuffer["feature_type"] = response.data.data[i]["type"];
                    databuffer["feature_icon"] = this.seticon(response.data.data[i]["feature_name_en"]);
                    this.feature.push(databuffer);
                  }
                
                }
              }
              this.loadprogress = false;
              console.log("ff",this.feature);
            }else{
               Toast.fire({
              icon: "error",
              title: response.data.errorCode + ': ' + response.data.errorMessage,
            });
            }
          }).catch(error => {
          
          console.log(error);
          Toast.fire({
            icon: "error",
            title: this.$t("toast.cannotconnectonebox")
          });
        });
          console.log("ddd",this.datafeature.department_feature);
          
          if(this.datafeature.staff_feature !== undefined){
            this.nametitle = this.datafeature.staff_name_th;
             console.log("dww");
            for(const key in this.datafeature.staff_feature){
            //console.log(this.datafeature.staff_feature[key]);
              if(this.datafeature.staff_feature[key] === 'Y'){
                this.selected.push(key);          
              }
            }
          }else if(this.datafeature.department_feature !== undefined){
            this.nametitle = this.datafeature.department_name;
            console.log("dee");
            
            for(const key in this.datafeature.department_feature){
            //console.log(this.datafeature.staff_feature[key]);
              if(this.datafeature.department_feature[key] === 'Y'){
                this.selected.push(key);          
              }
            }
          }else if(this.datafeature.business_feature !== undefined){
            this.nametitle = this.datafeature.first_name_th;
            for(const key in this.datafeature.business_feature){
            //console.log(this.datafeature.staff_feature[key]);
              if(this.datafeature.business_feature[key] === 'Y'){
                this.selected.push(key);          
              }
            }
          }
         console.log(this.selected);
    },
    seticon(parameter){   
      let feature_icon
      if(parameter === "my_drive"){
        feature_icon = ["cloud", "green"]; 
      }else if(parameter === "my_drive_bu"){
        feature_icon = ["mdi-domain", "dark-blue"]; 
      }else if(parameter === "file_s"){
        feature_icon = ["star", "green"]; 
      }else if(parameter === "inbox"){
        feature_icon = ["mdi-inbox-arrow-down-outline", "green"]; 
      }else if(parameter === "outbox"){
        feature_icon = ["mdi-inbox-arrow-up-outline", "green"]; 
      }else if(parameter === "shared"){
        feature_icon = ["folder_shared", "green"]; 
      }else if(parameter === "trash"){
        feature_icon = ["delete_sweep", "green"]; 
      }else if(parameter === "avi" ||
    parameter === "mpeg" ||
    parameter === "mov" ||
    parameter === "mp4"||
    parameter === "mkv" ||
    parameter === "wmv" ||
    parameter === "3gp" ||
    parameter === "flv"){
      dataifeature_icon = ["mdi-file-video-outline", "purple"];
    }else if(parameter === "shortcut"){
      dataicon=["mdi-file-link-outline","pink"]
    }
      return feature_icon
    },
   async settingfeature(){
      console.log("1",this.functionsetting);
      let bufferfeature =[];
      for(let i =0 ; i<this.feature.length;i++){
        bufferfeature.push(this.feature[i].feature_name_en);
      }
      
      let setfeature ={};
        for(let i =0 ; i<bufferfeature.length;i++){
          if(this.selected.length === 0){
            console.log("6");          
            setfeature[bufferfeature[i]] = "N";
          }else{
          for(let j=0;j<this.selected.length;j++){
            console.log("4",this.selected);
            if(bufferfeature[i] === this.selected[j]){
              console.log("2",this.selected[j]);
              
                setfeature[bufferfeature[i]] = "Y";
                break;
              
            }
            else{
                console.log("3",this.selected[j]);
                setfeature[bufferfeature[i]] = "N";
                
              
            }
          }  
          }  
          console.log(setfeature);
             
        }
        this.selected=[];
        let api_setting = "";
        let account_setting = "";
        if(this.functionsetting === "employee" ){         
          account_setting = this.datafeature.staff_id;
          api_setting = "/api/setting_feature_account" //VUEAPP_SERVICE_ADMIN_BUSINESS
          var payload = {
            account_id_admin : this.dataAccountId, 
            account_id : this.dataAccountId, 
            business_id: this.dataAccountActive.business_info.business_id, 
            admin: this.role_level,
            account_id_to_settings: account_setting,
            feature:setfeature, 
            };
        }else if(this.functionsetting === "department"){
          api_setting = "/api/setting_feature_department"
          var payload = {
            account_id : this.dataAccountId, 
            business_id: this.dataAccountActive.business_info.business_id, 
            admin: this.role_level,
            department_id: this.datafeature.department_id,
            feature:setfeature, 
            };
        }else if(this.functionsetting === "All"){
          api_setting = "/api/setting_feature_business"
          var payload = {
            account_id : this.dataAccountId, 
            business_id: this.dataAccountActive.business_info.business_id, 
            admin: this.role_level,
            feature:setfeature, 
        };
        }
           
      
        console.log("payload",payload);
         let auth = await gbfGenerate.generateToken();
         this.axios
        .post(
          process.env.VUE_APP_SERVICE_ADMIN + api_setting,
          payload, {headers: { Authorization: auth.code },}
        ) .then(response => {
          console.log(response);
          
          this.test = 1;
           if (response.data.status === "OK") {           
             console.log(response);  
             Toast.fire({
              icon: "success",
              title: this.$t("admintoast.settingsuccess")
            });
             this.$emit('loaddepartment')
             this.closemanagefeature();
           }else{
              Toast.fire({
              icon: "error",
              title: response.data.errorCode + ': ' + response.data.errorMessage,
            });
            this.closemanagefeature();
           }

        }).catch(error => {
          console.log("error",error);  
          Toast.fire({
              icon: "error",
              title: error
            });       
        })
       
    },
    closemanagefeature(){
      this.selected=[];
      this.test = 1;
      this.$emit('closemanagefeature')
    }
  },
  
}
</script>
<style>

</style>